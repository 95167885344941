// components/ProfilePage.js
import React from 'react';

function ProfilePage() {
  return (
    <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <iframe
        src="/pdf/cv.pdf"
        title="CV"
        width="100%"
        height="100%"
        style={{ border: 'none', maxWidth: '100%', maxHeight: '100%' }} // Ensure the iframe fills its container
      ></iframe>
    </div>
  );
}

export default ProfilePage;
