import React from 'react';
import profilePicture from './pic/nrjpp.jpg'; // Import your profile picture
import profileData from './profile.json'; // Import profile data from JSON file
import './HomePage.css'; // Import custom CSS styles
import Slider from './Slider'; // Import Slider component

function HomePage() {
  const value = profileData?.valueOf(); 
  if (value === undefined) {
    console.log("profileData is null or undefined");
    profileData = {
      "profileSummary": {
        "name": "Niranjan Acharya",
        "title": "Software Engineer",
        "summary": "Experienced Java/Kotlin Engineer with over 6 years of hands-on expertise in software development, adept at crafting high-quality solutions tailored to meet diverse business needs. Proficient in Spring framework, JPA, Hibernate, and Restful API development, I adhere to Test-Driven Development (TDD)principles to ensure code reliability and maintainability. With a strong foundation in PostgreSQL and adeptness in various databases, I excel in designing and optimizing database structures to enhance performance and scalability. My proficiency extends to cloud services, with comprehensive experience in leveraging the capabilities of Azure and AWS to architect, deploy, and manage cloud-native applications. Well-versed in software engineering paradigms, I am committed to continuous learning and innovation, delivering solutions that align with industry best practices and exceed client expectations."
      }
    }
  }

  const { name, title, summary } = profileData.profileSummary;

  return (
    <div>
      <div className="homepage-container">
        <div className="profile-picture-container">
          <img
            src={profilePicture}
            alt="Profile"
            className="profile-picture"
          />
        </div>
        <div className="profile-summary-container">
          <h1 className="profile-name">{name}</h1>
          <p className="profile-title">{title}</p>
          <p className="profile-description">{summary}</p>
        </div>
      </div>
      <Slider />
    </div>
  );
}

export default HomePage;
