import React, { useState } from 'react';
import './ContactPage.css'; // Import custom CSS styles

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: 'Contacting Niraj', // Default value for subject
    message: ''
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const messageTemplate = `
Hi,

Thanks for trying to contact me. Will reply as soon as possible.

Your Message:
*********************************************************
${formData.message}
*********************************************************

Thanks and Regards,
Niranjan
`;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const queryParams = new URLSearchParams({
      email: formData.email,
      subject: formData.subject,
      message: messageTemplate
    });

    try {
      const response = await fetch('https://dqm6g4w8nl.execute-api.us-east-1.amazonaws.com/default/sendEmail?' + queryParams.toString(), {
        method: 'GET',
        headers: {
          'x-api-key': 'hP2i5GUAuJ8J60pDRoCYK8GIXJvVosn8aMp58CKi', // Replace with your API key
        },
      });

      if (response.ok) {
        setMessage('Email sent successfully');
        setTimeout(() => {
          setMessage(null);
        }, 5000);
        // Reset form fields
        setFormData({ name: '', email: '', subject: 'Contacting Niraj', message: '' });
      } else {
        setMessage('Oops!! There was a problem connecting with the Lambda service.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setMessage('Oops!! There was a problem connecting with the Lambda service.');
    }
  };

  return (
    <div className="contact-form">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
        </div>
        <button type="submit">Send</button>
      </form>
      {message && <div className={message.includes('success') ? 'success-message' : 'error-message'}>{message}</div>}
    </div>
  );
}

export default ContactForm;
