// components/PortfolioPage.js
import React from 'react';

function PortfolioPage() {
  return (
    <div>
      <h1>Portfolio</h1>
      <h2>Projects</h2>
      <p><strong>CommChain</strong>  ( a blockchain-enabled digital solution for Bulk Commodity Logistics and Trade )&nbsp;</p>
      <p>Tech Stack: Spring Boot, Corda, API, Micro-Services, RESTful web services, JPA, Hibernate, Swagger, TTD, Java, Kotlin, Azure, PostgreSQL, Kafka</p>
      <p>&nbsp;Duration: 4 Years</p>
      <p><br /></p>
      <p><br /></p>
      <p><strong>Lares</strong>  (Philippines&apos; Land Titling and Registration project)&nbsp;</p>
      <p>Tech Stack: Java, Spring, Spring Boot, API, Micro-Services, RESTful web services, JPA, Hibernate, Swagger, TTD, Kotlin, AWS, PostgreSQL&nbsp;</p>
      <p>Duration: 3 Years</p>
    </div>
  );
}

export default PortfolioPage;
