import React, { useState, useEffect } from 'react';
import './AboutPage.css'; // Import custom CSS styles

function AboutPage() {
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchAboutHtml = async () => {
      try {
        const response = await fetch('/about.html');
        if (!response.ok) {
          throw new Error('Failed to fetch file');
        }
        const htmlContent = await response.text();
        setContent(htmlContent);
      } catch (error) {
        console.error('Error fetching or reading file:', error);
      }
    };

    fetchAboutHtml();
  }, []);

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      if (index <= content.length) {
        const displayedContent = content.substring(0, index);
        document.querySelector('.typing-animation').innerHTML = displayedContent;
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 1); // Decreased interval duration for faster typing speed

    return () => clearInterval(intervalId);
  }, [content]);

  return (
    <div className="about-page">

      <div className="typing-animation" />
    </div>
  );
}

export default AboutPage;
