import React, { useState, useEffect } from 'react';
import './Slider.css'; // Import slider CSS styles
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'; // Import arrow icons

function Slider() {
  const [imagePaths, setImagePaths] = useState([]);

  useEffect(() => {
    const importImages = async () => {
      const imageContext = require.context('./images', false, /\.(jpg|jpeg|png|gif)$/);
      const paths = await importAll(imageContext);
      setImagePaths(paths);
    };
    importImages();
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imagePaths.length);
      }
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [isPaused, imagePaths.length]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + imagePaths.length) % imagePaths.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imagePaths.length);
  };

  const handlePause = () => {
    setIsPaused(true);
  };

  const handleResume = () => {
    setIsPaused(false);
  };

  return (
    <div className="slider-container" onMouseEnter={handlePause} onMouseLeave={handleResume}>
      <div className="slider" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {imagePaths.map((path, index) => (
          <div className="slide" key={index}>
            <img src={path} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      <button className="prev-btn" onClick={handlePrev}><FaAngleLeft /></button>
      <button className="next-btn" onClick={handleNext}><FaAngleRight /></button>
    </div>
  );
}

export default Slider;

// Helper function to import all images from a folder
async function importAll(r) {
  const images = [];
  for (const key of r.keys()) {
    try {
      const imagePath = await import(/* webpackMode: "eager" */ "./images/" + key.split("/")[1]).then(module => module.default);
      images.push(imagePath);
    } catch (error) {
      console.error('Error loading image:', error);
    }
  }
  return images.filter(path => path !== undefined && path !== null);
}

